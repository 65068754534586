import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { catchError, distinctUntilChanged, lastValueFrom, map, of, shareReplay } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HotlinesPageDetailModalComponent } from 'src/app/components/modals/hotlines/hotlines-page-detail-modal/hotlines-page-detail-modal.component';
import { ConfigService } from 'src/app/services/config.service';
import { HelpersService } from 'src/app/services/helpers.service';
import { SearchService } from 'src/app/services/search.service';
import { DefaultService } from 'src/app/utils/api';
type Sort = { code: string; dir: 'asc' | 'desc' };

@Component({
    selector: 'app-hotline-pages',
    templateUrl: './hotline-pages.component.html',
    styleUrl: './hotline-pages.component.scss'
})
export class HotlinePagesComponent implements OnInit {
    configService = inject(ConfigService);
    helpersService = inject(HelpersService);
    searchService = inject(SearchService);
    router = inject(Router);
    route = inject(ActivatedRoute);
    modalService = inject(NgbModal);
    defaultService = inject(DefaultService);
    toastr = inject(ToastrService);
    appOverviewUrl = this.configService.getConfig().appOverviewUrl;

    SEARCH: { term?: string } = {};
    SEARCHED: any[] = [];
    SORT: Sort = { code: 'titel', dir: 'desc' };
    startRow: number = 0;
    RPP: number = 50;
    ready: boolean = false;
    loading: boolean = false;

    tableHeads: {
        name: string;
        code: string;
        sortable: boolean;
        width?: string;
    }[] = [
        { name: 'Titel', code: 'titel', sortable: false, width: '60%' },
        { name: 'Status', code: 'gepubliceerd', sortable: false, width: '13%' },
        { name: 'Laatst gewijzigd', code: 'datum', sortable: false, width: '13%' },
        { name: 'Gewijzigd door', code: 'editUsername', sortable: false, width: '13%' },
        { name: '', code: 'actions', sortable: false, width: '0.1%' }
    ];

    data: any;

    pageDetailModal: NgbModalRef;
    pageDetail$ = this.route.queryParamMap.pipe(
        map((paramMap: ParamMap) => {
            const id = paramMap.get('pageID');
            const mode = paramMap.get('mode');

            return mode || id
                ? {
                      id: id,
                      mode: !id ? 'create' : mode
                  }
                : undefined;
        }),
        distinctUntilChanged(),
        catchError((error) => {
            return of();
        }),
        shareReplay(1)
    );

    constructor() {
        // pageDetailModal
        this.pageDetail$.subscribe((next) => {
            if (this.pageDetailModal?.componentInstance) {
                this.pageDetailModal.dismiss();
            }

            if (next) {
                this.pageDetailModal = this.modalService.open(HotlinesPageDetailModalComponent, {
                    windowClass: 'main-modal detail-modal',
                    beforeDismiss: () => {
                        return (
                            this.pageDetailModal.componentInstance.canExit &&
                            this.pageDetailModal.componentInstance.canExit()
                        );
                    }
                });

                this.pageDetailModal.componentInstance.updateOverview.subscribe(() => this.getPages());
                this.pageDetailModal.dismissed.subscribe({
                    next: (dismissReason) => {
                        this.router.navigate([], {
                            queryParams: {
                                pageID: dismissReason?.routeTo?.id ?? undefined,
                                mode: dismissReason?.routeTo?.mode ?? undefined
                            },
                            queryParamsHandling: 'merge'
                        });
                        this.pageDetailModal = null;
                    }
                });
            }
        });
    }

    ngOnInit(): void {
        let searchQuery = this.route.snapshot.queryParamMap.get('query');
        let term: string;
        if (searchQuery) term = JSON.parse(searchQuery).term;
        this.SEARCH = {
            term: term
        };
        this.getPages();
    }

    async getPages(): Promise<void> {
        this.loading = true;

        this.SEARCHED = await this.searchService.formatTags(this.SEARCH, { term: undefined });

        const pages = await lastValueFrom(this.defaultService.meldpuntPagesGetPages(this.SEARCH.term));
        this.data = pages.reduce((acc, curr) => {
            const flattened = [curr, ...(curr?.subpaginas ?? [])];
            const formatted = flattened?.map((page) => {
                const actions: any = [
                    {
                        name: 'Wijzigen',
                        code: 'edit',
                        icon: 'pencil'
                    }
                ];
                const isSubpage: boolean = 'parentId' in page ? true : false;
                if (isSubpage) {
                    actions.push({
                        name: 'Verwijderen',
                        code: 'delete',
                        icon: 'trash',
                        class: 'delete-red',
                        confirmDelete: true
                    });
                }

                return {
                    id: page.id,
                    title: page.titel,
                    titel: {
                        type: 'pageTitle',
                        classList: 'text-color',
                        value: page.titel
                    },
                    gepubliceerd: {
                        type: 'default',
                        value: page.gepubliceerd ? 'Gepubliceerd' : 'Niet gepubliceerd'
                    },
                    editUsername: {
                        type: 'default',
                        value: page.editUsername
                    },
                    datum: {
                        type: 'ts',
                        ts: page.datum,
                        format: 'DD-MM-YYYY'
                    },
                    actions: {
                        type: 'actions',
                        actions: actions
                    },
                    isSubPage: isSubpage,
                    isPublished: page.gepubliceerd
                };
            });
            acc.push(...formatted);
            return acc;
        }, []);

        this.loading = false;
        this.ready = true;
    }

    openDetailPage(mode: string, item?: any) {
        this.router.navigate([], {
            queryParams: {
                pageID: item.id,
                mode: mode ? mode : 'view'
            },
            queryParamsHandling: 'merge'
        });
    }

    actionClick(item: any, action: string) {
        switch (action) {
            case 'edit':
                this.openDetailPage('edit', item);
                break;
            case 'delete':
                this.loading = true;
                this.defaultService.meldpuntPagesDeletePage(item.id).subscribe({
                    next: (next) => {
                        this.toastr.success('Subpagina werd succesvol verwijderd', 'Subpagina verwijderd');
                        this.getPages();
                    },
                    error: (error) => {
                        this.loading = false;
                        this.toastr.error('Subpagina kon niet worden verwijderd', 'Subpagina is niet verwijderd');
                    }
                });
                break;
        }
    }

    setStartRow(startRow: number) {
        this.startRow = startRow;
        this.search();
        document.documentElement.scrollTop = 0;
    }

    setSort(code: string, dir: 'asc' | 'desc') {
        this.SORT = { code: code, dir: dir };
        this.getPages();
    }

    resetSearch() {
        this.SEARCH = {};
        this.startRow = 0;
    }

    search = (reset?: boolean, SEARCH?: any) => {
        if (reset) this.resetSearch();
        SEARCH = SEARCH || this.SEARCH;
        this.SEARCH = { ...SEARCH };

        if (this.helpersService.objectIsEmpty(SEARCH)) {
            this.router.navigate([], {
                queryParams: {}
            });
        } else {
            const query = JSON.stringify(SEARCH);

            this.router.navigate([], {
                queryParams: {
                    query: JSON.stringify(SEARCH),
                    page: (Math.ceil(this.startRow / this.RPP) + 1).toString()
                },
                queryParamsHandling: 'merge'
            });
        }

        this.getPages();
    };
}
