<div class="scroll-xx saving--pre" [class.saving]="loading">
    <table class="table {{classes}}" [class.table--change-sequence]="changeSequence">
        <thead>
        <tr [class.no-pointer-events]="changeSequence">
            <ng-container *ngFor="let head of heads">
                <th [ngStyle]="{'min-width' : head.code === 'actions' ? '38px' : 'unset', 'width' : head.width ? head.width : 'auto'}" [class.sortable]="head.sortable" (click)="head.sortable && clickSort(head)"><div class="position-relative" [ngClass]="getSortingClass(head)">{{head.name}}<i *ngIf="head.sortable" [class.saving]="changeSequence" [ngClass]="getSortingClass(head)"></i></div></th>
            </ng-container>
        </tr>
        </thead>
        <tbody *ngIf="ready" id="sortable">
            <ng-container *ngFor="let item of data; trackBy: identify;index as $i">
                <tr [class.active]="item.trActive" [class.clickable]="clickable && !changeSequence" class="sortable-handle">
                    <ng-container *ngFor="let head of heads;trackBy: identify;">
                        <td (click)="clickData.emit({item: item, head: head.code})" [class.td--mw-0]="item[head.code]?.mw0" [class.shrink]="item[head.code] && item[head.code].type === 'actions'" [class.linethrough]="item && item.linethrough && item[head.code] && head.code !== 'actions'" >

                            <!-- default -->
                            <ng-container *ngIf="!item[head.code] || !item[head.code].type"><span [class.data-ellipsis]="item[head.code]?.ellipsis">{{head.code === 'empty' ? '' : item[head.code] | display}}</span></ng-container>
                            
                            <!-- icon -->
                            @if (item[head.code] && item[head.code].type === 'icon') {
                                <i class="im-icon {{item[head.code]?.classList}}" [style.background-image]="'url(' + item[head.code]?.iconPath + ')'" [class.asset-icon]="item[head.code]?.iconPath"></i>
                            }


                            <!-- default type (with props) -->
                            <div *ngIf="item[head.code] && item[head.code].type === 'default'" [class.data-ellipsis-parent]="item[head.code]?.ellipsis"><span [class.data-ellipsis]="item[head.code]?.ellipsis" [ngStyle]="{'width' : item[head.code]?.cellMaxWidth ? item[head.code]?.cellMaxWidth : 'unset' }" [class]="item[head.code].classList" [ngClass]="item[head.code].color ? 'text-color-' + item[head.code].color : ''">{{item[head.code].value | display}}</span></div>

                            <!-- Pagetitle -->
                            <div *ngIf="item[head.code] && item[head.code].type === 'pageTitle'" [class.data-ellipsis-parent]="item[head.code]?.ellipsis">
                                <i *ngIf="item.isSubPage && item.title" class="im-icon-arrow-newline" style="padding-right: 8px;"></i>
                                <span [class.data-ellipsis]="item[head.code]?.ellipsis" [ngStyle]="{'width' : item[head.code]?.cellMaxWidth ? item[head.code]?.cellMaxWidth : 'unset' }" [class]="item[head.code].classList" [ngClass]="item[head.code].color ? 'text-color-' + item[head.code].color : ''">{{item[head.code].value | display}}</span>
                            </div>

                            <!-- titleSubtitle -->
                            <ng-container *ngIf="item[head.code] && item[head.code].type === 'titleSubtitle'">
                                <div class="d-flex flex-column">
                                    <span class="text-color title">
                                        {{item[head.code].title | display}}
                                        <ng-container *ngIf="item[head.code] && item[head.code].stamps">
                                            <div class="d-inline-block" [class.app-tooltip-wrap]="item[head.code].tooltip">
                                                <span *ngFor="let stamp of item[head.code].stamps" class="stamp mr-xxs" [class]="stamp.classList">
                                                    {{stamp.value}}
                                                </span>
                                                <div *ngIf="item[head.code].tooltip" class="app-tooltip app-tooltip-default-centered">{{item[head.code].tooltip}}</div>
                                            </div>
                                        </ng-container>
                                    </span>
                                    <span class="fontsize-s" style="margin-top: 0px;">
                                        {{item[head.code].subtitle | display}}
                                        <ng-container *ngIf="item[head.code] && item[head.code].stampsSubtitle">
                                            <div class="d-inline-block" [class.app-tooltip-wrap]="item[head.code].tooltipSubtitle">
                                                <span *ngFor="let stamp of item[head.code].stampsSubtitle" class="stamp" [class]="stamp.classList">
                                                    {{stamp.value}}
                                                </span>
                                                <div *ngIf="item[head.code].tooltipSubtitle" class="app-tooltip app-tooltip-default-centered">{{item[head.code].tooltipSubtitle}}</div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="item[head.code] && item[head.code].afterSubtitle">
                                            {{item[head.code].afterSubtitle}}
                                        </ng-container>
                                    </span>
                                </div>
                            </ng-container>
                            
                            <!-- avatar -->
                            <ng-container *ngIf="item[head.code] && item[head.code].type === 'avatar'"><app-avatar [user]="item[head.code].user" [image]="item[head.code].image" [title]="item[head.code].title" [subtitle]="item[head.code].subtitle" size="40" fontSize="14"></app-avatar></ng-container>
                            
                            <!-- arrayShowMore -->
                            <ng-container *ngIf="item[head.code] && item[head.code].type === 'arrayShowMore'">
                                <app-arr-show-more
                                    [items]="item[head.code].values"
                                    [showAmount]="item[head.code].showAmount"
                                ></app-arr-show-more>
                            </ng-container>
                            
                            <!-- checkmark -->
                            <ng-container *ngIf="item[head.code] && item[head.code].type === 'checkmark'">
                                <div class="checkmark">
                                <i *ngIf="item[head.code].checked" class="im-icon im-icon-check-round-filled" style="color: #21A054;"></i>
                                <i *ngIf="!item[head.code].checked" class="im-icon im-icon-check-round-filled" style="background-color: #D7DADD; border-radius: 100px; color: #D7DADD;" ></i>
                                </div>
                            </ng-container>
                            
                            <!-- ts -->
                            <ng-container *ngIf="item[head.code] && item[head.code].type === 'ts'"><span style="white-space: nowrap;">{{item[head.code].ts | formatTs: item[head.code].format}}</span></ng-container>

                            <!-- html -->
                            <ng-container *ngIf="item[head.code] && item[head.code].type === 'html'"><span [innerHTML]="item[head.code].value | safe : 'html'" [class.data-ellipsis]="item[head.code]?.ellipsis"></span></ng-container>
                            
                            <!-- app-button -->
                            <ng-container *ngIf="item[head.code] && item[head.code].type === 'app-button'">
                                <app-button classes="{{item[head.code].actions?.classes}}" text="{{item[head.code].actions?.text}}"></app-button>
                            </ng-container>
                        
                            <!-- actions -->
                            <ng-container *ngIf="item[head.code] && item[head.code].type === 'actions'">
                                @if (!changeSequence) {
                                    <div *ngIf="item[head.code].actions && item[head.code].actions.length" class="action-button d-flex align-items-center actions position-relative" (click)="helper.actionIndex == $i ? setActionIndex(null) : setActionIndex($i); $event.stopPropagation();" appClickOutside (clickOutside)="setActionIndex(null); clickOutsideActions()">
                                        <i class="im-icon im-icon-actions"></i>
                                        <app-popover [open]="helper.actionIndex === $i" positionClass="left" (click)="setActionIndex(null); $event.stopPropagation();">
                                            <ul (click)="setActionIndex(null)">
                                                <li class="position-relative" *ngFor="let action of item[head.code].actions; trackBy: identify;" appClickOutside (clickOutside)="popoverHelper = null" (click)="action.confirmDelete ? $event.stopPropagation() || popoverHelper = 'delete_' + $i : clickAction.emit({item: item, action: action.code});" [ngClass]="action.class || ''">
                                                    <i *ngIf="action.icon" class="im-icon im-icon-{{action.icon}}"></i> 
                                                    {{action.name}}
                                                    <app-confirm-delete *ngIf="action.confirmDelete" (confirm)="clickAction.emit({item: item, action: action.code}); dismissPopover(); setActionIndex(null);" (cancel)="dismissPopover()" [open]="popoverHelper === 'delete_' + $i" positionClass="left"></app-confirm-delete>
                                                </li>
                                            </ul>
                                        </app-popover>
                                    </div>
                                } @else {
                                    <div class="change-sequence-icon-wrap"><i class="im-icon im-icon-drag-and-drop"></i></div>
                                }
                            </ng-container>
                            
                        </td>
                    </ng-container>
                </tr>
            </ng-container>
            <ng-container *ngIf="canAddRows">
                <tr>
                    <ng-container *ngFor="let head of heads">
                        <td *ngIf="head.canAddField">
                            <app-field-text 
                                [model]="addRowFormSTR[head.code]" 
                                (modelChange)="addRowFormSTR[head.code] = $event;" 
                                label=""
                                [error]=""
                                [placeholder]="head.name">
                            </app-field-text>
                        </td>
                    </ng-container>
                    <td>
                        <button (click)="addRow(addRowFormSTR)" class="button default"><i class="im-icon im-icon-plus"></i>Voeg toe</button>
                    </td>
                </tr>
            </ng-container>
        </tbody>
        <!-- shimmer -->
        <tbody *ngIf="!ready" class="shimmer-body">
            <ng-container *ngFor="let item of shimmerRows">
                <tr>
                    <td [attr.colspan]="heads.length"><app-shimmer></app-shimmer></td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>
<!-- emptystate -->
<div *ngIf="!loading && data && !data.length && emptyState" class="pl-xs pr-xs mt-s">
    <app-empty-state [value]="emptyState"></app-empty-state>
</div>
<!-- pagination / export -->
<div *ngIf="data && data.length" class="row mt-l">
    <div class="col-md-4">
        <div class="d-flex justify-content-end">
            <!--  -->
        </div>
    </div>
    <div class="col-md-4">
        <div class="d-flex justify-content-center" *ngIf="totalRows > RPP">
            <app-pagination 
                [RPP]="RPP"
                [startRow]="startRow"
                [totalRows]="totalRows"
                (startRowChange)="startRowChange.emit($event)">
            </app-pagination>
        </div>
    </div>
    <div class="col-md-4 d-flex flex-row align-items-center">
        <div *ngIf="exportLabel" class="d-flex w-100 justify-content-end">
            <button (click)="clickExport.emit()" [class.disabled]="exportBusy" class="button-link fontsize-s export-btn">
                @if (exportBusy) {
                    <app-inline-spinner class="mr-xxxs"></app-inline-spinner>
                } @else {
                    <i class="im-icon im-icon-download"></i>
                }
                <span>{{exportLabel}}</span>
            </button>
        </div>
    </div>
</div>