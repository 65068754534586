<span class="close-modal-outside"><i (click)="dismiss()" class="im-icon im-icon-x-light"></i></span>
<div class="modal-wrap">

    @if (!ready) {
        <app-spinner [centerVertically]="true"></app-spinner>
    } @else {


        <div class="saving--pre" [class.saving]="submitting" [attr.inert]="submitting ? '' : null">
            <!-- header -->
            <div class="detail-modal-header d-flex flex-row justify-content-between align-items-center" style="min-height: 36px;">
                <h1 style="min-height: 36px;" class="ellipsis">
                    @if (mode === 'create') {
                        Nieuwe subpagina
                    } @else {
                        {{page.titel}}
                    }
                </h1>

                <div class="h-100 d-flex align-items-center" >
                    @if (mode === 'view') {
                        @if (page.parentId) {
                            <div class="position-relative ml-xs" appClickOutside (clickOutside)="confirmDelete = undefined">
                                <button class="button tertiary delete-red" (click)="confirmDelete = 'true'" [class.disabled]="submitting"><i class="im-icon im-icon-trash delete-red icon-l mr-xxxs" style="transform: translateY(-1px);"></i><span>Verwijderen</span></button>
                                <app-confirm-delete (confirm)="deletePage(id, true); confirmDelete = undefined" (cancel)="confirmDelete = undefined" [open]="confirmDelete" positionClass="left" positionClassV="bottom"></app-confirm-delete>
                            </div>
                        }
                        <!-- edit -->
                        <button class="button tertiary ml-xs" (click)="mode = 'edit'" [class.disabled]="submitting"><i class="im-icon im-icon-pencil icon-l mr-xxxs"></i><span>Wijzigen</span></button>
                    }
                    @if (mode === 'edit') {
                        <button class="button tertiary" (click)="resetForm(); mode = 'view'" [class.disabled]="submitting"><span>Annuleer</span></button>
                        <app-button classes="primary-default ml-xs" icon="check-round-filled" text="Bewaren" [disabled]="submitting" [submitting]="submitting" (emitClick)="submit()"></app-button>
                    }
                </div>

            </div>

            <!-- content -->
            <div class="detail-modal-body">
                <div class="row display-row">
                    <div class="col-xl-4">
                        <!-- Titel -->
                        <div class="display-content">
                            @if (mode === 'view' || !page.parentId) {
                                <div class="display-label">Titel</div>
                                <div class="display-value d-flex flex-row">
                                    <span>{{page.titel | display}}</span>
                                </div>
                            } @else {
                                <app-field-text
                                    [model]="FORM.titel" 
                                    (modelChange)="FORM.titel = $event; formChange();" 
                                    label="Titel"
                                    [error]="validation?.titel"
                                    placeholder="Titel"
                                    maxWidth="420"
                                ></app-field-text>
                            }                          
                        </div>
                    </div>
                </div>
                <!-- Inhoud -->
                <div class="row display-row">
                    <div class="col-md-8">
                        <div class="display-content">
                            @if (mode === 'view') {
                                <div class="display-label">Inhoud</div>
                                <div class="display-value d-flex flex-row" style="border: 1px solid #D7DADD; border-radius: 4px; padding: 24px;">
                                    <div class="page-content meldpunt-richtext">
                                        <span class="page-content" [innerHTML]="page?.inhoud | display | safe : 'html'"></span>
                                    </div>
                                </div>
                            } @else {
                                <app-field-richtext 
                                    class="meldpunt-richtext"
                                    [model]="FORM.inhoud" 
                                    (modelChange)="FORM.inhoud = $event; formChange();" 
                                    [label]="'Inhoud'"
                                    [expanded]="true"
                                    [error]="validation?.inhoud"
                                    [meldpuntEditor]="true"
                                ></app-field-richtext>
                            }       
                        </div>
                    </div>
                </div>
               
                <div class="row display-row">
                    <div class="col-md-4">
                        <!-- Date last change -->
                        <div class="display-content">
                            @if (mode === 'view') {
                                <div class="display-label">Datum laatste wijziging</div>
                                <div class="display-value d-flex flex-row">
                                    <span>{{page.datum | formatTs: 'DD-MM-YYYY'}}</span>
                                </div>
                            }
                            @else {
                                <app-field-date 
                                    [model]="FORM.datum" 
                                    (modelChange)="FORM.datum = $event; formChange();" 
                                    label="Datum laatste wijziging"
                                    placeholder="dd-mm-yyyy"
                                    [error]="validation?.datum"
                                ></app-field-date>
                            }
                        </div>
                    </div>
                </div>
                
                <div class="row display-row">
                    <div class="col-md-4">
                        <!-- published -->
                        <div class="display-content">
                            @if (mode === 'view') {
                                <div class="display-label">Gepubliceerd</div>
                                <div class="display-value d-flex flex-row">
                                    <span>{{page.gepubliceerd | yesNo | display}}</span>
                                </div>
                            }
                            @else {
                                <app-field-radios 
                                    class="d-block"
                                    label="Gepubliceerd"
                                    [model]="FORM.gepubliceerd ? 'yes' : 'no'"
                                    (modelChange)="FORM.gepubliceerd = ($event === 'yes' ? true : false)"
                                    [error]="validation?.gepubliceerd"
                                    [options]="[{title: 'Ja', value: 'yes'},{title: 'Nee', value: 'no'}]"
                                    [optionWidth]="80"
                                    [optionColumnWidth]=""
                                ></app-field-radios>
                            }
                        </div>
                    </div>
                </div>
                
                @if (page.slug === 'meer' && mode === 'view') {
                    <div class="table--detail">
                        <h2>Subpagina's</h2>
                        @if (page.subpaginas) {
                            <table>
                                <thead>
                                    <tr>
                                        <th style="width: 31.5%;">Titel</th>
                                        <th style="width: 31.5%;">Laatst gewijzigd</th>
                                        <th style="width: 31.5%;">Gewijzigd door</th>
                                        <th style="width: 5%;">Volgorde</th>
                                        <th style="width: .1%; min-width: 38px;"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    @for (subpage of page.subpaginas; track subpage.id) {
                                        <tr>
                                            <td class="subpage-title"><span (click)="openPage(subpage.id)">{{subpage.titel | display}}</span></td>
                                            <td>{{subpage.editTS | formatTs: 'DD-MM-YYYY'}}</td>
                                            <td>{{subpage.editUsername | display}}</td>
                                            <td class="change-sequence text-align--end">
                                                @if (!$first) {
                                                    <button (click)="changeSequence($index, ($index - 1))">
                                                        <i class="im-icon im-icon-arrow-rounded-up"></i>
                                                    </button>
                                                }
                                                @if (!$last) {
                                                    <button class="ml-4" (click)="changeSequence($index, ($index + 1))">
                                                        <i class="im-icon im-icon-arrow-rounded-down"></i>
                                                    </button>
                                                }
                                            </td>
                                            <td>
                                                <div class="actions-popover-button">
                                                    <i class="im-icon im-icon-actions" [ngbPopover]="popContent" #p="ngbPopover" [autoClose]="'outside'" (hidden)="confirmDelete = undefined" popoverClass="actions-popover" [placement]="['bottom-right', 'auto']"></i>
                                                </div>
                                                <ng-template #popContent>
                                                    <ul>
                                                        <li (click)="openPage(subpage.id, 'edit')"><i class="im-icon im-icon-pencil"></i><span>Wijzigen</span></li>
                                                        <li><button (click)="confirmDelete = $index" [class.disabled]="submitting" class="delete-red"><i class="im-icon im-icon-trash"></i><span>Verwijderen</span></button></li>
                                                        @if (confirmDelete === $index) {
                                                            <app-confirm-delete (confirm)="deletePage(subpage.id); confirmDelete = undefined; p.close()" (cancel)="confirmDelete = undefined" [open]="confirmDelete === $index" positionClass="left" positionClassV="bottom"></app-confirm-delete>
                                                        }
                                                    </ul>
                                                </ng-template>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        } @else {
                            <div class="nosubpage">Er zijn geen subpagina's aan deze pagina gelinkt</div>
                        }
                        <div class="detail-modal-table-add">
                            <button class="add-subpage" (click)="createSubpage()"><i class="im-icon im-icon-plus-round"></i>Subpagina toevoegen</button>
                        </div>
                    </div>
    
                }
                
                @if (mode === 'create') {
                    <div class="h-100 d-flex align-items-center" >
                        <app-button classes="primary-default" icon="check-round-filled" text="Bewaren" [disabled]="submitting" [submitting]="submitting" (emitClick)="submit()"></app-button>
                        <button class="add-cancel ml-xs" (click)="dismiss()" [class.disabled]="submitting"><span>Annuleer</span></button>
                    </div>
                }

            </div>

            @if (page.id) {
                <app-history
                    [createTS]="page.createTS"
                    [createUsername]="page.createUsername"
                    [editTS]="page.editTS"
                    [editUsername]="page.editUsername"
                ></app-history>
            }
        </div>
    }

    
</div>